.navOuter {
	position: sticky;
	top: -1px;
	z-index: 900;
}

.nav {
	position: absolute; inset: 0; width: 100%;
}

.navInner {

	&.dark {
		--border-color: black;
	}
}